@use "responsive" as *;
.photos {
  margin: 8px auto;
  display: block;
  max-width: 400px;
  text-indent: 0;
  @media (min-width: 940px) {
    max-width: none;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  img {
    display: inline-block; margin: 4px;
    height: 160px;
    @media #{$mobile} {
      width: 100%; height: auto;
      margin: 4px auto;
    }
  }
}

img.photo {
  display: block;
  max-width: 400px;
  @media #{$mobile} {
    float: none;
    width: 100%; height: auto;
    margin: 4px auto;
  }
}

a.videolink {
    margin: 8px auto;
  display: block;
  border-radius: 4px;
  border: 1px solid #bec3c8;
  box-shadow: 0px 0px 4px #96a5a5;
  max-width: 560px;
  @media #{$mobile} {
    width: 100%;
    height: auto;
    max-width: 400px;
  }
  @media #{$standard} {
    margin: {top: 16px; bottom: 16px;}
  }

  img {display: block; width: 100%;}
}
