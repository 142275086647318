@use "responsive" as *;

iframe.youtube {
  margin: 8px auto;
  display: block;
  border-radius: 4px;
  border: 1px solid #bec3c8;
  box-shadow: 0px 0px 4px #96a5a5;
  @media #{$mobile} {
    width: 100%;
    height: auto;
    max-width: 400px;
    float: none!important;
    margin: 8px 0;
  }
  @media #{$standard} {
    margin: {top: 16px; bottom: 16px;}
  }
}

table.timing {
  border-spacing: 16px 0;
  width: 100%;
  * {font-size: 12pt; text-align: left;}
  caption {font-weight: bold}
  td:first-child {
    width: 64px;
    text-align: right;
    vertical-align: top;
  }
}
