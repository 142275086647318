@use "colors" as *;
@use "responsive" as *;
@use "animage" as *;
@use "photos" as *;
@use "youtube" as *;

* {
  margin: 0; padding: 0;
  font: {
    family: 'Open Sans', sans-serif;
    size: inherit;
  }
}
html {font-size: 10pt}
header, footer {font-size: 12pt}
h1,h2,p,ul,ol,dl {margin-top: 8px}
a {color: $blue}
p {text-indent: 16px;}
img {
  border-radius: 4px;
  border: 1px solid #bec3c8;
  box-shadow: 0px 0px 4px #96a5a5;
}
ul, ol {text-indent: 8px}
dl {
  dt {
    font-weight: bold;
    display: inline-block;
    padding-right: 0.5em;
    &::after{content: ' '}
  }
  dd {
    display: inline;
    &::after {content: '\a'; white-space: pre}
  }
}
ul, ol {list-style-position: inside;}
li p:first-child {display: inline}
mark {background: none; color: $sun /*$amethyst*/; font-weight: 600;}
header {
  color: $whale;
  a {color: inherit}
}
footer {clear: both}

blockquote {
  margin: 12px 8px;
  padding: 12px 8px 12px 12px;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0px 0px 4px $whale;
  @media #{$standard} {
    background-image: radial-gradient(at 10px 10px, white, $cloud);
    box-shadow: 0px 0px 4px $iron;
  }
  p:first-child {
    background-image: url(/assets/img/qleft.png);
    background-repeat: no-repeat;
    background-size: 18px 18px;
    background-position: 0 2px;
  }

  p {
    text-indent: 24px;
    margin: 4px; padding: 0;
  }
  span.qauthor {
    display: table;
    margin: 8px 8px 0px auto;
    min-height: 24px;
    padding-left: 24px;
    text-indent: 0px;
    background-image: url(/assets/img/qright.png);
    background-repeat: no-repeat;
    background-size: 18px 18px;
    background-position: 0 2px;
    &::before {content: " — "}
  }
}


@media #{$standard} {
  html {margin: 0 auto; padding: 0px; max-width: 1024px;}
  body {min-width: 800px}
  p {text-indent: 32px;}
  ul {/*margin: 8px 0;*/ text-indent: 16px; list-style-position: inside;}
  header {
    height: 128px;
    color: $silver;
    a {color: inherit}
  }
  main {margin: 0 auto;}
  footer {
    margin-top: 16px;
    border-top: 2px solid $persia;
    color: $whale;
    a {color: $whale}
    &.short {margin-left: 168px;}
  }
}

a#avatar {
  display: block;
  position: absolute; top: 4px; left: 4px;
  width: 64px; height: 64px;
  background: {
    image: url(/assets/img/logo.svg);
    repeat: no-repeat;
    size: 100% 100%;
  }
  @media #{$tablet} {
    background: {
      image: url(/assets/img/avatar.png);
      repeat: no-repeat;
      size: 100% 100%;
    }
  }
  @media #{$standard} {
    position: static; float: left;
    width: 128px; height: 128px;
    margin: 0 16px;
  }
}

a.share {
  display: inline-block; width: 24px; height: 24px; margin: 0 2px;
  &.fb {background: {image: url(/assets/img/fb.png); size: 100% 100%}}
  &.vk {background: {image: url(/assets/img/vk.png); size: 100% 100%}}
  &.tw {background: {image: url(/assets/img/tw.png); size: 100% 100%}}
  @media #{$standard} {width: 28px; height: 28px;}
}

.card {
  font-style: italic;
  .presentation {
    [itemprop="name"] {font-weight: 700;}
    div {
      display: none;
      @media #{$tablet} {display: block}
    }
  }
  .contacts {
    font-weight: 600;
    display: block;
    a  {
      display: block;
      margin-left: 12px;
      padding-left: 24px;
      background: {size: 20px 20px; repeat: no-repeat; position: 0px 3px;}
      &[itemprop="email"] {background-image: url(/assets/img/email-whale.png)}
      &[itemprop="telephone"] {background-image: url(/assets/img/phone-whale.png)}
    }
  }
}

header .card {
  margin: auto 2px auto auto;
  width: 196px;
  @media #{$tablet} {
    padding-left: 32px;
    background: url(/assets/img/logo-silver.png) no-repeat;
    background-size: 24px 24px;
    display: flex; color: $silver; width: 440px;
    margin: {top: 12px; bottom: 16px}
    .presentation div {padding-left: 32px}
    .contacts {
      border-left: 1px solid $silver;
      margin-left: 10px;
      padding-left: 10px;
      a {
        margin-left: 0;
        &[itemprop="email"] {background-image: url(/assets/img/email-silver.png)}
        &[itemprop="telephone"] {background-image: url(/assets/img/phone-silver.png)}
      }
    }
  }
  @media #{$standard} {
    margin: 12px 8px 0 0; width: auto; height: auto;
    display: flex; float: right;
    .presentation div {padding-left: 32px}
    .contacts {
      border-left: 1px solid $silver;
      margin-left: 10px;
      padding-left: 10px;
      a {
        margin-left: 0;
        &[itemprop="email"] {background-image: url(/assets/img/email-silver.png)}
        &[itemprop="telephone"] {background-image: url(/assets/img/phone-silver.png)}
      }
    }
  }
}
footer .card {
  width: 220px;
  margin: 12px auto;
  font-weight: 600;
  opacity: 0.8;
  color: $whale;
  a {color: inherit;}
  @media #{$tablet} {
    width: 256px;
    margin: 12px 0 12px auto;
    padding-left: 70px;
    opacity: 0.8;
    background: {
      image: url(/assets/img/logo.png);
      position: 0 4px;
      size: 64px 64px;
      repeat: no-repeat;
    }
    .contacts {
      padding-left: 32px;
      [itemprop="email"] {background-image: url(/assets/img/email-whale.png)}
      [itemprop="telephone"] {background-image: url(/assets/img/phone-whale.png)}
    }
  }
}

nav {
  display: flex; background: $whale;
  margin: 8px 0;
  .home {background-image: url(/assets/img/home-white.png)}
  .books {background-image: url(/assets/img/books-white.png)}
  .trainings {background-image: url(/assets/img/trainings-white.png)}
  .about {
    background-image: url(/assets/img/about-white.png);
    background-size: 17px 24px;
    background-position: 5px 6px;
    padding-left: 22px;
  }
  #menu {
    z-index: 1;
    &>a{
      background-image: url(/assets/img/menu-white.png);
    }
    // position: relative;
    &>div {
      display: none;
      position: fixed; top: 106px; left: auto; right: auto;
      min-width: 160px;
      @media #{$standard} {top: 116px;}
      a, span {
        display: block;
        text-transform: capitalize;
        background: white;
        border-color: $whale;
        border-width: 2px;
        border-style: solid;
        color: $whale;
        padding: 8px 12px;
        &:first-child {
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
        }
        &:last-child {
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }
      a:hover, span {
        background-color: $whale;
        color: white;
      }
    }
    @mixin menu {
      &>div {display: block}
      &>a {
        background-image: url(/assets/img/menu-out-white.png);
        @media #{$standard} {background-image: url(/assets/img/menu-out.png)}
        &>span {
          display: none;
        }
      }
    }
    &.active {@include menu}
    @media(hover: hover) and (pointer: fine) {&:hover {@include menu}}
    // background-size: 17px 24px;
    // background-position: 5px 6px;
    // padding-left: 22px;
  }
  @media #{$standard} {
    background: none;
    clear: right;
    margin: 0 0 0 168px;
    padding-top: 34px;
    border-bottom: 2px solid $whale;
    .home {background-image: url(/assets/img/home.png)}
    .books {background-image: url(/assets/img/books.png)}
    .about {background-image: url(/assets/img/about.png)}
    .trainings {background-image: url(/assets/img/trainings.png)}
    #menu {
      &>a {
        background-image: url(/assets/img/menu.png)
      }
    }
  }
  &>a {
    &:first-child {margin-left: 4px;}
    @media #{$standard} {
      margin: {left: 0; bottom: -2px;}
      border-bottom: 2px solid $whale;
    }
  }
  a {
    text-decoration: none; text-transform: uppercase;
    color: white; font-weight: 600;
    display: block;
    background: {
      size: 24px 24px;
      position: 6px 6px;
      repeat: no-repeat;
    }
    padding: 6px 6px 6px 30px;
    span {display: none} min-height: 24px;
    &.active {
      color: $whale;
      background-color: white;
      box-shadow: 0 -2px 2px $whale;
      &.home {background-image: url(/assets/img/home.png)}
      &.books {background-image: url(/assets/img/books.png)}
      &.trainings {background-image: url(/assets/img/trainings.png)}
      &.about {
        background-image: url(/assets/img/about.png);
        padding-left: 24px;
      }
      margin: {left: 4px; right: 4px;}
      padding: 6px 6px 6px 34px;
      span {display: inline-block}
    }
    @media #{$tablet} {
      span {display: inline-block}
      padding: 6px 6px 6px 34px;
      &.about {padding-left: 24px}
      &.active {
        span {display: inline-block}
        padding: 6px 6px 6px 34px;
        &.about {padding-left: 24px}
      }
    }
    @media #{$standard} {
      color: $whale; font-size: 12pt; font-weight: 400;
      height: auto;
      background: {
        size: 24px 24px;
        repeat: no-repeat;
        position: 8px 5px;
      }
      padding: 4px 4px 4px 36px;
      &.about {padding-left: 25px}
      span {display: inline-block}
      &.active {
        box-shadow: 2px -2px 1px $whale;
        border: {
          top: 2px solid $whale;
          right: 2px solid $whale;
          top-right-radius: 8px;
          left: 2px solid $whale;
          bottom: 2px solid white;
        }
        &:first-child {border-left: none /*!important*/; margin-left: 0}
      }
    }
  }
} // nav

div.breadcrumbs {
  font-size: 12pt;
  padding: 0 4px;
  @media #{$standard} {
    padding: 5px 0 0 0;
  }
}


.float-right {
  float: right;
  margin: 0 auto;
}

.clear {clear: both}

.inline-block {display: inline-block;}

menu {
  color: $blue;
  a {color: $blue}
}

figure {
  margin: 8px 4px;
  div {
    display: flex; flex-wrap: wrap; justify-content: center; gap: 4px;
    * {
      flex-grow: 0;
      flex-shrink: 3;
    }
  }
  figcaption {
    color: $amethyst;
    font: {size: 12pt; weight: 600; style: italic;}
    text-align: center;
  }
}
