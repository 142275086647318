@use "responsive" as *;

@keyframes animage {
  25% {opacity: 1}
  75% {opacity: 0}
}
div.animage {
  position: relative;
  @media #{$mobile} {
    float: none!important;
    margin: {left: auto!important; right: auto!important;}

  }
  img {
    position: absolute;
    left: 0; top: 0;
    // left: 50%; top: 50%;
    // transform: translate(-50%,-50%);
    opacity: 0;
    animation-name: animage;
    animation-timing-function: ease;
    animation-iteration-count: infinite;
  }
}
